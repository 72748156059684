.sidebar, body[color-theme="dark"] {
  flex: 1;
  height: calc(100vh - 50px);
  /* background-color: rgb(251, 251, 255); */
  /* background-color: #182227; */
  position: sticky;
  top: 50px;
    margin-right: 20px;
    background: #182227;
    background-light: rgba(255, 255, 255, .15);
    --clr-text: #FCE8Ec;
    --clr-text-light: #FCE8Ecbb;
    --clr-element: #FFABC2;
    --clr-element-hover: #FFABC2bb;
    color-shadow: rgba(0, 0, 0, .2);
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}
