.home{
    flex: 4;   
}



.homeWidgets{
    display: flex;
    margin: 20px;
}
@media only screen and (max-width:479px){
    
}