.containner{
    display: flex;
    margin-top: 10px;
}

.home, body[color-theme="dark"] {
    --bg: #182227;
    --bg-light: rgba(255, 255, 255, .15);
    --clr-text: #FCE8Ec;
    --clr-text-light: #FCE8Ecbb;
    --clr-element: #FFABC2;
    --clr-element-hover: #FFABC2bb;
    --clr-shadow: rgba(0, 0, 0, .2);
}

.link{
    text-decoration: none;
    color: inherit;
}